// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-berufskolleg-schulnummersuche-js": () => import("./../src/pages/berufskolleg/schulnummersuche.js" /* webpackChunkName: "component---src-pages-berufskolleg-schulnummersuche-js" */),
  "component---src-pages-bildungsblog-tags-js": () => import("./../src/pages/bildungsblog/tags.js" /* webpackChunkName: "component---src-pages-bildungsblog-tags-js" */),
  "component---src-pages-glossar-zu-apps-js": () => import("./../src/pages/glossar-zu-apps.js" /* webpackChunkName: "component---src-pages-glossar-zu-apps-js" */),
  "component---src-pages-glossar-zu-fortbildungen-js": () => import("./../src/pages/glossar-zu-fortbildungen.js" /* webpackChunkName: "component---src-pages-glossar-zu-fortbildungen-js" */),
  "component---src-pages-glossar-zur-beruflichen-bildung-im-digitalen-wandel-js": () => import("./../src/pages/glossar-zur-beruflichen-bildung-im-digitalen-wandel.js" /* webpackChunkName: "component---src-pages-glossar-zur-beruflichen-bildung-im-digitalen-wandel-js" */),
  "component---src-pages-informatik-grundlagen-division-mit-rest-js": () => import("./../src/pages/informatik/grundlagen/division-mit-rest.js" /* webpackChunkName: "component---src-pages-informatik-grundlagen-division-mit-rest-js" */),
  "component---src-pages-informatik-grundlagen-stellenwertsysteme-js": () => import("./../src/pages/informatik/grundlagen/stellenwertsysteme.js" /* webpackChunkName: "component---src-pages-informatik-grundlagen-stellenwertsysteme-js" */),
  "component---src-pages-mathematik-koordinatensystem-generator-js": () => import("./../src/pages/mathematik/koordinatensystem-generator.js" /* webpackChunkName: "component---src-pages-mathematik-koordinatensystem-generator-js" */),
  "component---src-pages-sitemap-js": () => import("./../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-templates-blog-post-template-js": () => import("./../src/templates/blogPostTemplate.js" /* webpackChunkName: "component---src-templates-blog-post-template-js" */),
  "component---src-templates-glossary-template-js": () => import("./../src/templates/glossaryTemplate.js" /* webpackChunkName: "component---src-templates-glossary-template-js" */),
  "component---src-templates-page-template-js": () => import("./../src/templates/pageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-post-list-template-js": () => import("./../src/templates/postListTemplate.js" /* webpackChunkName: "component---src-templates-post-list-template-js" */),
  "component---src-templates-tag-template-js": () => import("./../src/templates/tagTemplate.js" /* webpackChunkName: "component---src-templates-tag-template-js" */)
}

