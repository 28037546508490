module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-prismjs"},{"resolve":"gatsby-remark-images","options":{"maxWidth":800,"linkImagesToOriginal":false}},{"resolve":"gatsby-source-filesystem","options":{"path":"/Users/ralf/WebstormProjects/kolleg/src/markdown-pages"}},{"resolve":"gatsby-source-filesystem","options":{"name":"images","path":"/Users/ralf/WebstormProjects/kolleg/src/images"}}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","icon":"./src/images/icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"a48180754f77a5abbab8ce065658cbe7"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
