/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

require("prismjs/themes/prism-solarizedlight.css")
require("prismjs/plugins/line-numbers/prism-line-numbers.css")

require("mmenu-light/dist/mmenu-light")
require("mmenu-light/dist/mmenu-light.polyfills")
require("mmenu-light/dist/mmenu-light.css")
